<template>
  <div>

    <b-card
      class="mb-base"
    >

      <b-row>
        <b-col cols="10">
          {{ $t('Company Details') }}
        </b-col>
        <b-col cols="2">
          <vx-badge
            class="mb-1"
            :color="Company.status==='Active'?'light-success':Company.status==='Blocked'?'light-danger':'light-warning'"
          >
            {{ $t(Company.status) }}
          </vx-badge>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-row>
            <b-col cols="4">
              <div class="img-container mb-2">
                <b-avatar
                  size="70px"
                  :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
                />
              </div>
            </b-col>
            <b-col cols="8">
              <table>
                <tr>
                  <td><h3>{{ Company.first_name +' '+ Company.last_name }}</h3></td>
                </tr>
                <tr>
                  <td><h5>{{ Company.email }}</h5></td>
                </tr>
                <tr>
                  <td><h5>{{ Company.phone }}</h5></td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-row align-v="center">
            <b-col cols="4">
              <h6>{{ Company.business_name }}</h6>
              <h6>{{ Company.contact_person }}</h6>
            </b-col>
            <b-col cols="8">
              <h3>Address</h3> <td><h5>{{ Company.address }}</h5></td>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <b-row align-h="end">
        <b-col
          cols="3"
          lg="3"
          md="2"
        >
          <vx-badge
            color="light-success"
            class="mb-1"
          >
            {{ $t(Company.created_from) }}
          </vx-badge>

        </b-col>
        <b-col
          cols="3"
          lg="3"
          md="2"
        >
          <vx-badge
            color="light-warning"
          >
            {{ $t(Company.updated_from) }}
          </vx-badge>

        </b-col>
      </b-row>
    </b-card>
    <h1 v-if="Company.jobs.length>0">
      Jobs
    </h1>
    <b-row>
      <b-col
        v-for="(tr, indextr) in Company.jobs"
        :key="indextr"
        class="d-flex"
        cols="12"
        lg="6"
      >
        <b-card class="w-100">
          <b-card-title>
            <b-row>
              <b-col cols="12">
                <h2>{{ Company.job_title }} at {{ Company.business_name }}</h2>
              </b-col>
            </b-row>
          </b-card-title>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
              >
                <h3 class="section-title">
                  Description
                </h3>
                {{ tr.descriptions }}
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <h3 class="section-title">
                  Requirements
                </h3>
                {{ tr.requirements }}
              </b-col>
              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Job Address
                </h3>
                {{ tr.job_address }}
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <h3 class="section-title">
                  Employment Type
                </h3>
                <vx-badge color="light-success">
                  {{ tr.employment_type }}
                </vx-badge>
              </b-col>
              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Status Of The Job
                </h3>
                <vx-badge :color="tr.status==='unavailable'?'light-danger':'light-success'">
                  {{ tr.status }}
                </vx-badge>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Category
                </h3>
                <vx-badge color="light-success">
                  {{ tr.category }}
                </vx-badge>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-card-title>
            {{ $t('Devices') }}
          </b-card-title>
          <b-card-body>
            <vs-table
              stripe
              :data="[]"
              no-data-text=""
            >
              <template slot="thead">
                <vs-th>{{ $t('Browser') }}</vs-th>
                <vs-th>{{ $t('Device') }}</vs-th>
                <vs-th>{{ $t('Device Type') }}</vs-th>
                <vs-th>{{ $t('platform') }}</vs-th>
                <vs-th>{{ $t('Logged in At') }}</vs-th>
              </template>
              <template slot-scope="{}">
                <vs-tr
                  v-for="(tr, indextr) in Company.devices"
                  :key="indextr"
                >
                  <vs-td :data="tr.browser">
                    <img
                      v-if="tr.browser_image"
                      width="20px"
                      :src="require(`@/assets/images/browsers/${tr.browser_image}.png`)"
                    >
                  </vs-td>
                  <vs-td :data="tr.device">
                    {{ tr.device }}
                  </vs-td>
                  <vs-td :data="tr.device_type">
                    <fa-icon
                      color="primary"
                      pack="fas"
                      :icon="tr.device_type_icon"
                    />
                  </vs-td>
                  <vs-td :data="tr.platform">
                    <fa-icon
                      color="primary"
                      pack="fab"
                      :icon="tr.platform_icon"
                    />
                  </vs-td>
                  <vs-td :data="tr.created_at">
                    <VxTooltip
                      color="success"
                    >
                      <template #title>
                        {{ $t('Created_At') }}
                      </template>
                      <template #text>
                        {{ tr.created_at }}
                      </template>
                      <vx-badge
                        pack="far"
                        icon="calendar-plus"
                        color="light-success"
                        icon-color-bg="success"
                        icon-color="null"
                      >
                        {{ $t(tr.created_from) }}
                      </vx-badge>
                    </VxTooltip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  data:()=>({
  }),
  methods:{
  },
  computed:{
    Company(){
      return this.$store.getters['company/company']
    }
  },
  mounted(){
  },
  created() {
      this.$store.dispatch('company/GetCompanyData',this.$route.params.userId)
  }
}
</script>
